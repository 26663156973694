import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import Seo from "../components/seo/seo"
import Link from "../utilities/link"
import { useTranslation } from "react-i18next"
import { usePageContext } from "../pageContext"
import Modal from "../components/common/modal"
import SubscriptionForm from "../components/forms/subscriptionForm"

const Service = ( { data }) => {
  const { t } = useTranslation()
  const { lang } = usePageContext();

    return (
      <Layout>
        <Seo 
          title={data.markdownRemark.frontmatter.seoTitle}
          description={data.markdownRemark.frontmatter.seoDesc}
        />
        <Modal
          component={<SubscriptionForm />}
        /> 
        <div className="section is-medium is-gradient" style={{zIndex: 1}}>
            {/* <Img 
              fluid={data.markdownRemark.frontmatter.image} 
              style={{
                position: "absolute",
                overflow: "hidden",
              }}
              imgStyle={{
                width: "100%",
                height: "100%",
                opacity: "1",
              }}
              className="image-wrapper-height-auto"
            /> */}
     
        </div>

        <section className="section" style={{zIndex: 5, marginTop: '-150px'}}>
            <div className="container"></div>
                <div className="columns is-multiline">
                    <div className="column is-6 is-offset-2 has-light-background" style={{marginBottom: '20px'}}>
                        <div className="content is-large" style={{padding: '50px'}}>
                            <h1 className="title is-1">
                                {data.markdownRemark.frontmatter.title}
                            </h1>
                            <h2 className="title is-4">{data.markdownRemark.frontmatter.subtitle}</h2>
                        </div>
                    </div>
                    <div className="column is-4"></div>
                    <div className="column is-12 no-mobile"></div>
                    <div className="column is-12 no-mobile"></div>
                    <div className="column is-12 no-mobile"></div>
                    <div className="column is-1 no-mobile"></div>
                    <div className="column is-2 no-mobile">
                        <div className="content has-text-right">
                            <p>{t('service.menuTitle')}</p>
                            {data.allMarkdownRemark.edges
        .filter(edge => edge.node.frontmatter.category === "servizio" && edge.node.frontmatter.lang === lang)
        .map(({ node }) => <Link to={`/${node.frontmatter.translatedPath}`} className="title is-4 is-bottom-bordered">{node.frontmatter.title}</Link> )}
                            
                        </div>
                    </div>
                    <div className="column is-1 no-mobile"></div>
                    <div className="column is-7">
                      <div className="content is-medium"
                        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                      />
                    </div>
          </div>
        </section>
        <section className="section is-small">
          <div className="content has-text-centered">
            <Link to="/contatti" title={t('header.titleTagContatti')} className="button is-info is-outlined is-medium">{t('header.Contatti')}</Link>
          </div>
        </section>
      </Layout>
    )
  }

export default Service

export const infoService = graphql`
  query serviceInfo($localizedPath: String!) {
    markdownRemark(fields: { slug: { eq: $localizedPath } }) {
      html
      frontmatter {
        lang
        title
        subtitle
        seoTitle
        seoDesc
        image {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 900) {
              src
            }
          }
        }
      }
    }
    allMarkdownRemark {
      edges {
       node {
         frontmatter {
           path
           translatedPath
           lang
           category
           title
         }
       }
     }
   }
  }
`
