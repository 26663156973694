import React, { useEffect, useState} from 'react'

const Modal = (props) => {

    // gestione popup modal

  const [showModal, setShowModal] = useState(false);

  // caricamento dopo 10 secondi

  useEffect(() => {
    // dopo 10 secondi dal caricamento della pagina, compare il popup
    const timer = setTimeout(() => setShowModal(true), 10000);
    //poi il timer si resetta
    return () => clearTimeout(timer);
  }, []);


    return (
   
        <div className={showModal ? `modal is-active`: 'modal'}>
            <div className="modal-background"></div>
            <div className="modal-content">{props.component}</div>
            <button
                className={`modal-close is-large`}
                aria-label="close"
                onClick={() => setShowModal(false)}
            ></button>
        </div>

    )
}
  
export default Modal
